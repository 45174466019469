<script>
  import IdFilter from '@/components/grid/filters/IdFilter';

  export default {
    extends: IdFilter,
    computed: {
      placeholder() { return this.$gettext('Org number'); },
    },
    methods: {
      onInputBoxChanged(value) {
        if (value !== this.searchKey) {
          clearInterval(this.requestTimer);
          this.searchKey = value;
          this.requestTimer = setTimeout(() => {
            this.params.parentFilterInstance((instance) => {
              instance.onFloatingFilterChanged('contains', value);
            });
          }, 500);
        }
      },
    }
  };
</script>
