<script>
  import CId from '@/components/grid/cells/shared/CId';

  export default {
    extends: CId,
    computed: {
      godmodeDemanderUrl() { return this.params.data?.owner.godmodeDemanderUrl; }
    }

  };
</script>
