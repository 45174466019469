<script>
  import CName from '@/components/grid/cells/shared/CName';

  export default {
    extends: CName,
    computed: {
      name() { return this.propData?.owner.fullName; },
      email() { return this.propData?.owner?.email; },
      phone() { return this.propData?.owner?.fullPhoneNumber; },
      showActions() { return false; }
    }

  };
</script>
