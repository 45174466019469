(<template>
  <div class="next-job">
    <div class="next-job_wrapper">
      <div class="next-job__info">
        <template v-if="hasNextJob">
          <p class="next-job__id">{{ nextJobId }}</p>
          <p class="next-job__start-time">{{ startTime }}</p>
        </template>
        <template v-else>
          <p class="next-job__empty">-</p>
        </template>
      </div>
      <div class="next-job__links">
        <button v-if="godmodeDemanderUrl"
                class="sk-link sk-link--default godmode-link"
                @click="openGodmodeLink(godmodeDemanderUrl)">{{ $gettext('[K]') }}</button>
        <p>{{ nextCustomer }}</p>
      </div>
      <div class="next-job__links next-interpreter__link">
        <button v-if="godmodeSupplierUrl"
                class="sk-link sk-link--default godmode-link"
                @click="openGodmodeLink(godmodeSupplierUrl)">{{ $gettext('[T]') }}</button>
        <p>{{ nextInterpreter }}</p>
      </div>
    </div>
  </div>
</template>)

<script>
  import CNextJob from '@/components/grid/cells/customer/CNextJob';

  export default {
    extends: CNextJob,
    computed: {
      nextJob() { return this.params.data?.nextJob; },
      hasNextJob() { return this.nextJob ? Object.keys(this.nextJob).length > 0 : false; },
      nextJobId() { return this.nextJob?.id; },
      godmodeSupplierUrl() { return this.nextJob?.godmodeSupplierUrl; },
      godmodeDemanderUrl() { return this.nextJob?.godmodeDemanderUrl; },
      startTime() { return this.$moment(this.nextJob?.startTime).format('YYYY.MM.DD HH:mm'); },
      nextCustomer() { return this.nextJob?.demanderName; },
      nextInterpreter() { return this.nextJob?.supplierName; },
    },
  };
</script>
