(<template>
  <div class="enterprise">
    <div class="enterprise__wrapper">
      <p class="enterprise-name">{{ name || email }}</p>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      classes() { return this.propData?.classes; },
      name() { return this.propData?.name; },
      email() { return this.propData?.email; }
    }
  };
</script>
<style scoped>
.enterprise {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.enterprise__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.enterprise-name {
  overflow: hidden;
  font-weight: 600;
  text-overflow: ellipsis;
}
</style>
