<script>
  import CustomerNameFilter from '@/components/grid/filters/shared/TextFilter';

  export default {
    extends: CustomerNameFilter,
    computed: {
      placeholder() { return this.$gettext('Name'); },
    },
  };
</script>
