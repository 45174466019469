(<template>
  <div class="enterprises">
    <!------- Page Header ------->
    <div class="enterprises__header">
      <div class="enterprises__header--left">
        <h1 class="enterprises__header-title">{{ $gettext('Enterprises') }}</h1>
      </div>
    </div>
    <div class="enterprises__cont">
      <!------- Grid Component ------->
      <ag-grid :column-defs="columnDefs"
               :grid-options="gridOptions"
               :fetch-data-func="fetchDataFunc"
               :grid-data="gridData"
               @interface="getChildInterface" />
    </div>
  </div>
</template>)

<script>
/* eslint-disable vue/no-unused-components */
// For Grid filter components
  import CustomerNameFilter from '@/components/grid/filters/shared/TextFilter';
  import EnterpriseNameFilter from '@/components/grid/filters/EnterpriseNameFilter';
  import IdFilter from '@/components/grid/filters/IdFilter';
  import OrgNumberFilter from '@/components/grid/filters/OrgNumberFilter';
  import PageWithGridMixin from '@/mixins/PageWithGridMixin';
  // ---- Cells ----//
  import CId from '@/components/grid/cells/enterprise/CId';
  import CEnterpriseName from '@/components/grid/cells/enterprise/CName';
  import COwner from '@/components/grid/cells/enterprise/COwner';
  import CEnterpriseNextJob from '@/components/grid/cells/enterprise/CEnterpriseNextJob';
  import {mapState} from 'vuex';

  export default {
    components: {
      'owner-name-filter': CustomerNameFilter,
      'enterprise-name-filter': EnterpriseNameFilter,
      'id-filter': IdFilter,
      'org-number-filter': OrgNumberFilter,
      'c-id': CId,
      'c-owner': COwner,
      'c-enterprise-name': CEnterpriseName,
      'c-next-job': CEnterpriseNextJob,
      'ag-grid': () => import('@/components/grid/GridComponent')
    },
    mixins: [PageWithGridMixin],
    data() {
      return {
        currentPage: Number(this.$route.query.page) || 1,
        items: this.$store.state.filterItemsAmount || 10,
        filterParams: {
          id: {
            filter: this.$route.query.id || '',
            type: 'equals'
          },
        },
        ransackParams: {},
        fetchGridDataAction: 'AllEnterprisesStore/getManagerEnterprises',
        // ------- Tabs Data ------- //
        tabForUpdate: ''
      };
    },
    computed: {
      ...mapState('AllSuppliersStore', {
        enterprisesData: (state) => state.enterprisesData || {},
      }),
      columnDefs() {
        return [
          {
            headerName: this.$gettext('ID'),
            field: 'id',
            minWidth: 140,
            flex: 1,
            sortable: true,
            floatingFilterComponent: 'id-filter',
            ...this.textFilterNoMenu,
            cellRendererSelector: (params) => {
              return {
                component: 'c-id',
                params: params
              };
            }
          },
          {headerName: this.$gettext('Name'),
           field: 'name',
           flex: 2,
           sortable: true,
           ...this.textFilterNoMenu,
           floatingFilterComponent: 'enterprise-name-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-enterprise-name',
               params: params
             };
           }},
          {headerName: this.$gettext('Org number'),
           field: 'orgNumber',
           minWidth: 130,
           flex: 1,
           cellClass: 'center-grid-column-data',
           floatingFilterComponent: 'org-number-filter',
           ...this.textFilterNoMenu},
          {headerName: this.$gettext('Owner'),
           field: 'ownerLookupFields',
           minWidth: 240,
           flex: 2,
           sortable: true,
           ...this.textFilterNoMenu,
           floatingFilterComponent: 'owner-name-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-owner',
               params: params
             };
           }},
          {headerName: this.$gettext('Departments'),
           field: 'departmentsCount',
           cellClass: 'center-grid-column-data',
           minWidth: 120},
          {headerName: this.$gettext('Members'),
           field: 'membersCount',
           cellClass: 'center-grid-column-data',
           minWidth: 120},
          {headerName: this.$gettext('Job Posted'),
           field: 'postedJobsCount',
           cellClass: 'center-grid-column-data',
           minWidth: 120},
          {headerName: this.$gettext('Next job'),
           flex: 3,
           cellClass: 'no-cell-pad-right',
           field: 'nextJob',
           cellRendererSelector: (params) => {
             return {
               component: 'c-next-job',
               params: params
             };
           }},
        ];
      },
      gridData() {
        return {
          page: this.currentPage,
          filterParams: this.filterParams
        };
      }
    },
    beforeRouteEnter(to, from, next) {
      if (to.query.page == {} || to.query.page == null || to.query.page == undefined) {
        next(() => {
          return {path: to.path, query: {page: 1}};
        });
      }
      next();
    },
    beforeRouteLeave(to, from, next) {
      this.$store.dispatch('ModalStore/closeModal');
      this.$store.commit('TTHotlineStore/clearStore');
      this.$store.commit('AllEnterprisesStore/clearStore');
      next();
    },
    childInterface: {
      updateData: () => {}
    },
  };
</script>
<style scoped>
.enterprises {
  margin: 10px 20px;
}

.enterprises__cont {
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 5%);
}

.enterprises__header {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.enterprises__header--left {
  display: flex;
}

.enterprises__header-title {
  font-size: 24px;
}
</style>
